<template>
  <div class="thanks">
    <component :is="currentView"></component>
  </div>
</template>

<script>
import { INFO_SCREEN_TYPES_PARAMS_ALIAS, THANK_YOU_TYPES_PARAMS_ALIAS } from '@/utils/constants';
import INFO_SCREENS_COMPONENTS from '@/components/infoScreens';

export default {
  name: 'InfoScreen',
  computed: {
    currentView() {
      const { INFO_SCREEN_TYPE, TYPE } = this.$route.params;
      return INFO_SCREENS_COMPONENTS[INFO_SCREEN_TYPES_PARAMS_ALIAS[INFO_SCREEN_TYPE]][
        THANK_YOU_TYPES_PARAMS_ALIAS[TYPE]
      ];
    },
  },
  created() {
    const { INFO_SCREEN_TYPE, TYPE } = this.$route.params;
    if (!INFO_SCREEN_TYPE || !TYPE) this.$router.push({ name: 'notFound' });
  },
};
</script>
